import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HeroLayout } from "../components/hero-layout";

export const CheckMailboxPage = () => {
  return (
    <Layout withMaxWidth={false}>
      <SEO title={"Check your mailbox"} />
      <HeroLayout>
        <div className="text-content-container confirm-email">
          <h1 className="mdc-typography--headline4">Check your mailbox</h1>
          <p className="mdc-typography--subtitle1">
            We have sent you an email with a confirmation link. Please open your
            mailbox and follow the link to continue.
          </p>
          <p className="mdc-typography--subtitle1">
            If you have not received our email, please{" "}
            <a href="mailto:support@remeet.com">contact us</a>.
          </p>
        </div>
      </HeroLayout>
    </Layout>
  );
};

export default CheckMailboxPage;
